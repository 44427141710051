<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
const app = document.querySelector('#app');
function callbackFunctionMonitorChanges() {
    app.classList.remove('full-height');
    console.log(app.getBoundingClientRect().height, window.innerHeight)
    if (app.getBoundingClientRect().height <= window.innerHeight) {
        app.classList.add('full-height');
    }
}
 app.addEventListener('resize', callbackFunctionMonitorChanges);
</script>

<style>
</style>

