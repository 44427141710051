<template>
    <div :class="{'wrap-top': !this.showResults}">

    <div class="flex flex-center">

        <!--<header></header>-->

        <section  v-if="!this.finishedQuiz && !this.showResults">
            <div class="center-area">
                <div v-for="(quest, index) in questions" :key="quest.Text"  class="question">
                    <div v-if="index==current">
                        <h1>Question {{index+1}} of {{questions.length}}</h1>
                        <p v-html="quest.Text"></p>

                        <div class="has-text-centered" v-for="(opt, i) in quest.Options" :key="opt.Text">
                            <button type="button" class="button is-primary m-3 is-setwidth border-radius" v-on:click="nextQuestion(i+1, opt.Weight)">{{opt.Text}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="thanks" v-if="this.finishedQuiz && !this.showResults"></div>
        <div class="center-area margin-middle relative" v-if="this.finishedQuiz && !this.showResults">
            <div class="faded">
                <h1>That's it.</h1>
                <p>Thanks for completing the Aviva pension personality quiz.</p>
            </div>
            <div class="has-text-centered m-5">
                <button type="button"  v-on:click="showResultScreen()" class="button is-primary border-radius id-fixedwidth">Show me my pension profile</button>
            </div>
        </div>
        <div class="disclaimer"  v-if="this.finishedQuiz && !this.showResults">
            <p>
                Aviva Pension Trustees UK Limited Registered in England No. 2407799. Aviva, Wellington Row, York YO90 1WR. 
                Authorised and regulated by the Financial Conduct Authority. Firm reference number 465132. 
                Aviva Life & Pensions UK Limited Registered in England No. 3253947. Aviva, Wellington Row, York YO90 1WR. 
                Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the 
                Prudential Regulation Authority. Firm Reference Number 185896. 
                Aviva Investment Solutions UK Limited Registered in England No. 6389025. Aviva, Wellington Row, York YO90 1WR. 
                Authorised and regulated by the Financial Conduct Authority. Firm Reference Number 515334. 
                Telephone 0345 604 9915 – calls may be recorded <br />
                SP991596 08/2024
            </p>
        </div>


        <div class="result" v-if="this.showResults">
            <div class="center-area-large">
                <h1>{{this.result.Title}}</h1>
                <div class="has-text-centered" v-html="this.result.Content"></div>
                <div class="has-text-centered">
                    <span class="normal">{{this.result.LinkTextPrefix}} </span><a class="link" v-bind:href="this.result.Link">{{this.result.LinkText}}</a>
                </div>

                <div class="person">
                    <img v-bind:src="this.result.Image" v-bind:alt="this.result.Title" />
                </div>
            </div>
        </div>
    </div>

        <div class="logo">
            <a href='/'>
                <img src="/images/aviva-blue-bottom.png" alt="Aviva Logo" />
            </a>
        </div>
    </div>
</template> 

<script>
    import axios from 'axios';

    export default {
        name: 'QuestionView',
        props: {},
        mounted() {
            axios.get("/questions-bloomberg.json").then(response => {
                this.questions = [...response.data];
                this.total = this.questions.length;
            });
            axios.get("/results.json").then(response => {
                this.results = [...response.data];
            });
            axios.get("/quiz").then(response => {
                this.sessionid = response.data;
            });

            const app = document.querySelector('#app');
                app.classList.remove('full-height');
                console.log(app.getBoundingClientRect().height, window.innerHeight)
                if (app.getBoundingClientRect().height <= window.innerHeight) {
                    app.classList.add('full-height');
                }

            
        },
        data() {
            return {
                sessionid : '',
                questions: [],
                results: [],
                current: 0,
                score: 0,
                finishedQuiz: false,
                showResults: false,
                result: null
            }
        },
        methods: {
            nextQuestion: function (question, weight) {
                this.score += weight;
                this.current += 1;
                if (this.current >= this.questions.length) {
                    this.finishedQuiz = true;
                }

                axios.post("/quiz", { EventType: this.current.toString(), Argument: question.toString(), SessionId: this.sessionid });

                const app = document.querySelector('#app');
                app.classList.remove('full-height');
                console.log(app.getBoundingClientRect().height, window.innerHeight)
                if (app.getBoundingClientRect().height <= window.innerHeight) {
                    app.classList.add('full-height');
                }

            },
            showResultScreen: function () {
                var resultIndex = 0;
                for (var i = 0, len = this.results.length; i < len; i++)
                    if (this.score >= parseInt(this.results[i].minWeight) && this.score <= parseInt(this.results[i].maxWeight)) {
                        this.result = this.results[i];
                        resultIndex = i + 1;
                    }

                this.showResults = true;
                this.finishedQuiz = false;


                axios.post("/quiz", { EventType: "score", Argument: this.score.toString(), SessionId: this.sessionid });
                axios.post("/quiz", { EventType: "end", Argument: resultIndex.toString(), SessionId: this.sessionid });

                const app = document.querySelector('#app');
                //app.classList.remove('full-height');
                //console.log(app.getBoundingClientRect().height, window.innerHeight)
                if (window.innerWidth < 635) {
                    app.classList.remove('full-height');
                }

            }
        }
    };
</script>


<style>

</style>