<template>
    <div class="wrap">
        <div class="flex space-between">
            <div class="w-50 pt-5 pl-5">
                <!--<img src="/images/bloomberg.png" alt="Bloomberg"  />-->
            </div>
            <div class="w-50 has-text-right">
                <img src="/images/aviva-blue.png" alt="Aviva" class="aviva-logo-home" />
            </div>
        </div>

        <section class="center-area-large">
            <h1 style="color: #fff;margin-top: 0;">Aviva Pension Personality Quiz</h1>
            <div class="mt-large">
                <p>When it comes to your pension, are you a retirement rookie or are you so future focussed you're practically clairvoyant?</p>
                <p>Working with Bloomberg, we want to help you to better understand your pension so that you can get the most out of your savings.</p>
                <p>Take this quiz to discover your unique Aviva pension personality.</p>
                <div class="has-text-centered mt-xlarge mb-2">
                    <router-link :to="{ path:'/Question'}" class="button is-primary border-radius is-thirdwidth">Let's get started</router-link>
                </div>
                <div>
                    <p><small style="font-size: .7em;">Your responses will be treated in confidence and Bloomberg and Aviva will only receive aggregated results, not individual responses. Bloomberg and Aviva will use the results to support their analysis, research and member engagement and communication approach</small></p>
                </div>
            </div>
        </section>
    </div>
</template> 

<script>
    export default {
        name: 'HomeView',
        props: {},
        mounted() {
            const app = document.querySelector('#app');
            console.log(app.getBoundingClientRect().height, window.innerHeight)
            if (app.getBoundingClientRect().height < window.innerHeight) {
                app.classList.add('full-height');
            } else {
                app.classList.remove('full-height');
            }
        },
    };
</script>

