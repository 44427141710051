import Vue from 'vue';
import App from './App.vue';
import VueRouter  from "vue-router";
import HomeView from './components/HomeView.vue';
import QuestionView from './components/QuestionView.vue';
/*import Buefy from 'buefy'
import 'buefy/dist/buefy.css'*/

const routes = [
    { path: "/", component: HomeView },
    { path: "/Question", component: QuestionView, name: "question" }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})


Vue.config.productionTip = true;
//Vue.use(Buefy);
Vue.use(VueRouter);

window.app = new Vue({
    router,
    render: h => h(App),
    mounted: () => {
        
    }
}).$mount('#app');
